
import React from 'react'
import "./src/style/global.css"
import { GlobalContextProvider } from './src/utils/GlobalContextProvider';

export const wrapRootElement = ({ element }) => {

  return (
    <GlobalContextProvider>
      {element}
    </GlobalContextProvider>
  )
}

// export function onServiceWorkerUpdateReady() { window.location.reload(true) };
