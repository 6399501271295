import React from 'react'

export const GlobalStateContext = React.createContext(null) 
export const GlobalDispatchContext = React.createContext(null) 

const initialState = {
    theFirstTimeLoadPassed: false,
    scrollingBannerIsOpen: true,
    checkOutOpen: false,
    titlePage: 'ACCUEIL',
    addNewItem: 0,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'IT_IS_PASSED': {
            return {
                ...state,
                theFirstTimeLoadPassed: state.theFirstTimeLoadPassed === false && true,
            }
        }
        case 'OPEN_BANNER': {
            return {
                ...state,
                scrollingBannerIsOpen: state.scrollingBannerIsOpen === false && true,
            }
        }
        case 'CLOSE_BANNER': {
            return {
                ...state,
                scrollingBannerIsOpen: state.scrollingBannerIsOpen === true && false,
            }
        }
        case 'CHANGE_PAGE': {
            return {
                ...state,
                titlePage: action.newPage
            }
        }
        case 'ADD_ITEM': {
            return {
                ...state,
                addNewItem: action.newAddItem
            }
        }
        default:
            throw new Error('Error');
    }
}

export const GlobalContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}
